<template>
    <div id="flight-card">
        <transition-group v-if="flights.length" name="list" tag="div">
            <template v-for="flight in flights">
                <v-card outlined  v-if="!flight.mailOnlyFare" :key="flight.id" class="mb-5">
                    <v-card-title v-if="$route.name !== 'flightResults'" class="primary">
                        <v-row justify="space-between" class="pa-3">
                            <h2 class="text-h5 white--text text-capitalize">Package Flight</h2>
                            <v-btn text color="white" @click="$emit('goToResults')"><v-icon>mdi-airplane</v-icon> More Results</v-btn>
                        </v-row>
                    </v-card-title>
                    <v-card-text class="pt-5">
                        <div class="d-block d-md-flex justify-space-between align-center">
                            <div>
                                <v-row justify="space-between" align="center" v-for="(flightSegment, i) in flight.flightSegments" :key="i">
                                    <v-col>
                                        <img :src="flightSegment.Segments[0].MarketingAirlineLogoSM" width="50"/>
                                        <v-tooltip bottom color="blueDark darken-1">
                                            <template v-slot:activator="{ on }">
                                                <p v-on="on" class="airline font-weight-medium">{{flightSegment.Segments[0].MarketingAirlineName.substr(0, 15)}}...</p>
                                            </template>
                                            <span>{{flightSegment.Segments[0].MarketingAirlineName}}</span>
                                        </v-tooltip>
                                    </v-col>
                                    <v-col>
                                        <span class="body-2 font-weight-bold">{{ $d(new Date(flightSegment.Segments[0].DepartureDate.replace(/-/g, '/')), 'short') }}</span>
                                    </v-col>
                                    <v-col class="d-flex d-sm-none">
                                        <v-subheader>{{ $d(new Date(flightSegment.Segments[flightSegment.Segments.length - 1].ArrivalDate.replace(/-/g, '/')), 'short') }}</v-subheader>
                                    </v-col>
                                    <v-tooltip bottom nudge-top="30" color="blueDark darken-1">
                                        <template v-slot:activator="{ on }">
                                            <v-col cols="12" sm="6" class="text-center">
                                                <div style="display: flex; justify-content: center; position: relative;">
                                                    <div>
                                                        <v-subheader class="caption pr-2">
                                                            {{ flightSegment.Segments[0].DepartureTime }},
                                                            <strong class="strong">{{ flightSegment.Segments[0].OriginLocationCode }}</strong>
                                                        </v-subheader>
                                                    </div>
                                                    <v-icon small color="primary">mdi-airplane-takeoff</v-icon>
                                                    <v-icon small color="primary">mdi-minus</v-icon>
                                                    <div v-if="flightSegment.stops > 0" style="display: flex;">
                                                        <!-- <div v-for="n in flightSegment.stops" :key="n"> -->
                                                            <!-- <v-icon small color="primary" style="transform: translateY(10px);" v-if="n >= 2">mdi-minus</v-icon> -->
                                                            <v-icon small color="secondary">mdi-airplane-marker</v-icon>
                                                        <!-- </div> -->
                                                    </div>
                                                    <v-icon small color="primary">mdi-minus</v-icon>
                                                    <v-icon small color="primary">mdi-airplane-landing</v-icon>
                                                    <div>
                                                        <v-subheader class="caption">
                                                            {{ flightSegment.Segments[flightSegment.Segments.length - 1].ArrivalTime }},
                                                            <strong>{{ flightSegment.Segments[flightSegment.Segments.length - 1].DestinationLocationCode }}</strong>
                                                        </v-subheader>
                                                    </div>
                                                    <p v-on="on" v-if="flightSegment.stops > 0" class="body-2 font-weight-regular" style="position: absolute; left: 50%; top: 100%; transform: translate(-50%, -50%); width: 50px;">{{$tc('flightSearch.card.stop', flightSegment.stops)}}</p>
                                                </div>
                                            </v-col>
                                            </template>
                                        <span style="transform: translateX(-50%)">
                                            <p v-for="n in flightSegment.stops" :key="n">{{flightSegment.Segments[n-1].DestinationLocation.split(',')[0]}} - <span>{{flightSegment.Segments[n-1].Duration}}h</span></p>
                                        </span>
                                    </v-tooltip>
                                    <v-col class="d-none d-sm-flex">
                                        <span class="body-2 font-weight-bold">{{ $d(new Date(flightSegment.Segments[flightSegment.Segments.length - 1].ArrivalDate.replace(/-/g, '/')), 'short') }}</span>
                                    </v-col>
                                </v-row>
                            </div>
                            <div class="text-center mx-auto price">
                                <span v-if="flight.pricingInfo.TotalFare.toFixed(2) - minPrice === 0" class="text-h6 mx-5 font-weight-bold primary--text text-no-wrap">
                                    {{$t('flightSearch.card.cheapestFlight')}}
                                </span>
                                <span
                                v-else-if="Math.round(((flight.pricingInfo.TotalFare - minPrice) * currency.rate).toFixed(2) / 50) * 50 > 0"
                                class="text-h6 mx-5 font-weight-bold primary--text text-no-wrap">
                                  <v-icon color="primary" class="mb-1">mdi-plus</v-icon>
                                  {{currency.code + ' ' + Math.round(((flight.pricingInfo.TotalFare - minPrice) * currency.rate).toFixed(2) / 50) * 50}}
                                </span>
                                <span v-else class="text-h6 mx-5 font-weight-bold primary--text text-no-wrap">
                                    {{$t('flightSearch.card.cheapestFlight')}}
                                </span>
                            </div>
                        </div>
                        <!-- <v-divider class="my-5"></v-divider> -->
                    </v-card-text>
                    <v-card-actions class="actions">
                        <v-row justify="space-between" align="center" class="pa-3">
                            <v-btn
                            text
                            small
                            color="blue"
                            @click="expand(flight.id)"
                            >
                                {{$t('flightSearch.card.moreDetails')}}
                                <v-icon right>{{ show && currentFlight === flight.id ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
                            </v-btn>
                            <div>
                                <v-btn :disabled="loading" class="mx-1" tile color="primary" @click="redirectToHotels(flight)">{{$t('flightSearch.card.selectFlight')}}</v-btn>
                            </div>
                        </v-row>
                    </v-card-actions>
                    <v-expand-transition>
                        <div v-show="show && currentFlight === flight.id">
                            <v-divider></v-divider>
                            <v-container>
                                <div v-for="(flightSegment, i) in flight.flightSegments" :key="i">
                                    <v-row v-if="i === 0" justify="space-between" class="pa-3">
                                        <h3 class="body-1 font-weight-bold primary--text">
                                            {{$t('flightSearch.departure')}} <span class="caption grey--text">{{ $d(new Date(flightSegment.Segments[0].DepartureDate.replace(/-/g, '/')), 'short')}} / {{flightSegment.TotalDuration}} {{$t('flightSearch.card.hour')}}</span>
                                        </h3>
                                        <v-btn color="secondary" v-if="flightSegment.baggage_rules && $route.name !== 'book'" tile small @click="baggageDialog = true; baggageRules = flightSegment.baggage_rules"><v-icon left>mdi-bag-checked</v-icon> {{$t('flightSearch.card.baggageRules')}}</v-btn>
                                    </v-row>
                                    <v-row v-else justify="space-between" class="pa-3">
                                        <h3 class="body-1 font-weight-bold primary--text">
                                            {{$t('flightSearch.return')}} <span class="caption grey--text">{{ $d(new Date(flightSegment.Segments[0].DepartureDate.replace(/-/g, '/')), 'short') }} / {{flightSegment.TotalDuration}} {{$t('flightSearch.card.hour')}}</span>
                                        </h3>
                                        <v-btn color="secondary" v-if="flightSegment.baggage_rules && $route.name !== 'book'" text small @click="baggageDialog = true; baggageRules = flightSegment.baggage_rules"><v-icon left>mdi-bag-checked</v-icon> {{$t('flightSearch.card.baggageRules')}}</v-btn>
                                    </v-row>
                                    <v-row v-for="(segment, n) in flightSegment.Segments" :key="n">
                                        <v-col cols="10" sm="3">
                                            <v-row>
                                                <v-col cols="4">
                                                    <img :src="segment.MarketingAirlineLogoSM" height="30"/>
                                                </v-col>
                                                <v-col cols="8">
                                                    <v-tooltip top color="blueDark darken-1">
                                                        <template v-slot:activator="{ on }">
                                                            <p v-on="on" class="body-2">{{segment.MarketingAirlineName.substr(0, 6)}}</p>
                                                        </template>
                                                        <span>{{segment.MarketingAirlineName}}</span>
                                                    </v-tooltip>
                                                    <p class="caption">Operated by {{flightSegment.Segments[0].OperatingAirlineName}}</p>
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                        <v-col cols="2" class="d-block d-sm-none">
                                            <p class="caption">
                                                <v-icon color="secondary">mdi-timer-outline</v-icon>
                                                {{segment.Duration}} {{$t('flightSearch.card.hour')}}
                                            </p>
                                        </v-col>
                                        <v-col cols="3" sm="2">
                                            <p class="body-2">{{ $d(new Date(segment.DepartureDate.replace(/-/g, '/')), 'short') }}</p>
                                        </v-col>
                                        <v-col cols="3" sm="2">
                                            <p class="body-2">{{segment.DepartureTime}}</p>
                                            <p class="body-2">{{segment.OriginLocation.substr(0, segment.OriginLocation.indexOf(','))}},{{segment.OriginLocationCode}}</p>
                                        </v-col>
                                        <v-col cols="3" sm="2">
                                            <p class="body-2">{{segment.ArrivalTime}}</p>
                                            <p class="body-2">{{segment.DestinationLocation.substr(0, segment.DestinationLocation.indexOf(','))}},{{segment.DestinationLocationCode}}</p>
                                        </v-col>
                                        <v-col cols="3" sm="2">
                                            <p class="body-2">{{ $d(new Date(segment.ArrivalDate.replace(/-/g, '/')), 'short') }}</p>
                                        </v-col>
                                        <v-col cols="12" sm="1" class="d-none d-sm-block">
                                            <p class="caption">
                                                <v-icon color="secondary">mdi-timer-outline</v-icon>
                                                {{segment.Duration}} {{$t('flightSearch.card.hour')}}
                                            </p>
                                        </v-col>
                                        <v-col cols="12" class="py-0 pb-5 text-right">
                                            <v-row no-gutters justify="space-around">
                                                <small><v-icon small>mdi-airplane</v-icon> {{segment.aircraft}} / {{$t('flightSearch.filters.flights')}} {{segment.FlightNumber}}</small>
                                                <small class="secondary--text">{{$t('flightSearch.card.bookingClass')}} ({{segment.BookingClass}})</small>
                                                <small v-if="segment.classCabin" class="secondary--text text-capitalize">{{$t('flightSearch.card.cabinClass')}} ({{segment.classCabin}})</small>
                                                <small v-if="segment.departureTerminal" class="secondary--text text-capitalize">{{$t('flightSearch.card.departureTerminal')}} ({{segment.departureTerminal}})</small>
                                                <small v-if="segment.arrivalTerminal" class="secondary--text text-capitalize">{{$t('flightSearch.card.arrivalTerminal')}} ({{segment.arrivalTerminal}})</small>
                                            </v-row>
                                            <v-divider v-if="n + 1 < flightSegment.Segments.length" class="mt-5"></v-divider>
                                            <div class="layover caption" v-if="n + 1 < flightSegment.Segments.length">{{$t('flightSearch.card.layover')}} {{flightSegment.stopsDuration[n].text}} h</div>
                                        </v-col>
                                    </v-row>
                                </div>
                            </v-container>
                        </div>
                    </v-expand-transition>
                </v-card>
            </template>
        </transition-group>
        <v-dialog max-width="700" v-model="baggageDialog">
            <v-card>
                <v-card-title
                class="headline blueDark white--text font-weight-regular"
                primary-title
                >
                <span>{{baggageRules.airlineName}}, <sub class="caption">{{baggageRules.airlineCode}} </sub> {{$t('flightSearch.card.baggageRules')}}</span>
                </v-card-title>
                <v-card-text v-if="baggageRules" class="text-right mt-5">
                    <a class="my-3" v-if="baggageRules.baggage_url && baggageRules.baggage_url !== ''" target="_blank" :href="baggageRules.baggage_url">{{$t('flightSearch.card.baggageRules')}}</a>
                    <div v-if="baggageRules.freePieces">
                        <p class="headline text-center secondary--text">{{$t('flightSearch.card.freePieces')}}</p>
                        <v-simple-table>
                            <template v-slot:default>
                            <thead>
                                <tr>
                                    <th class="text-left">
                                        {{$t('flightSearch.card.numberOfPieces')}}
                                    </th>
                                    <th class="text-left">
                                        {{$t('flightSearch.card.weight')}}
                                    </th>
                                    <th class="text-left">
                                        {{$t('flightSearch.card.size')}}
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td class="text-left">{{ baggageRules.freePieces.numberOfPieces }}</td>
                                    <td class="text-left">{{ baggageRules.freePieces.weight }}</td>
                                    <td class="text-left">{{ baggageRules.freePieces.size }}</td>
                                </tr>
                            </tbody>
                            </template>
                        </v-simple-table>
                    </div>
                    <div v-if="baggageRules.additionalCost">
                        <v-divider class="mt-2 mb-5"></v-divider>
                        <p class="headline text-center secondary--text">{{$t('flightSearch.addCost')}}</p>
                        <v-simple-table>
                            <template v-slot:default>
                            <thead>
                                <tr>
                                    <th class="text-left">
                                        {{$t('flightSearch.card.fees')}}
                                    </th>
                                    <th class="text-left">
                                        {{$t('flightSearch.card.weight')}}
                                    </th>
                                    <th class="text-left">
                                        {{$t('flightSearch.card.size')}}
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td class="text-left">$ {{ baggageRules.additionalCost.fees }}</td>
                                    <td class="text-left">{{ baggageRules.additionalCost.weight }}</td>
                                    <td class="text-left">{{ baggageRules.additionalCost.size }}</td>
                                </tr>
                            </tbody>
                            </template>
                        </v-simple-table>
                    </div>
                </v-card-text>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import packages from '@/requests/packages'
import { mapState } from 'vuex'

export default {
  props: ['flight'],
  data () {
    return {
      show: false,
      currentFlight: null,
      baggageDialog: false,
      baggageRules: [],
      flightId: null,
      snackbar: false,
      loading: false
    }
  },
  computed: {
    ...mapState(['packageDetails', 'currency']),
    flights () {
      if (this.$route.name === 'flightResults') return this.flight
      else return [this.flight]
    },
    minPrice () {
      return this.flights[0].pricingInfo.TotalFare.toFixed(2)
    }
  },
  methods: {
    getThisDate (date) {
      const [year, month, day] = date.split('-')
      const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
      return `${day} ${months[month - 1]} ${year}`
    },
    expand (id) {
      if (id === this.currentFlight) {
        this.show = !this.show
      } else {
        this.show = true
        this.currentFlight = id
      }
    },
    redirectToHotels (flight) {
      const store = this.$store.state
      const params = new URLSearchParams(this.$route.query.flightQuery)
      const terms = {}
      params.forEach((value, key) => {
        let decodedKey = decodeURIComponent(key)
        const decodedValue = decodeURIComponent(value)
        if (decodedKey.endsWith('[]')) {
          decodedKey = decodedKey.replace('[]', '')
          terms[decodedKey] || (terms[decodedKey] = [])
          terms[decodedKey].push(decodedValue)
        } else {
          terms[decodedKey] = decodedValue
        }
      })
      if (
        store.adults === Number(terms.adults) &&
        store.children + store.oldChildren === Number(terms.children_under_six) + Number(terms.children_above_six) &&
        store.infants === Number(terms.infants) &&
        store.classType.value === terms.classType &&
        ((terms.tripType === 'round' &&
        store.startDate === terms.departureDate &&
        store.returnDate === terms.arrivalDate &&
        store.originAirport.Code === terms.origin &&
        store.destinationAirport.Code === terms.destination
        ) ||
        (terms.tripType === 'multi' &&
        store.startDate === terms.departureDate[0] &&
        store.returnDate === terms.departureDate[1] &&
        store.originAirport.Code === terms.origin[0] &&
        store.destinationAirport.Code === terms.destination[0] &&
        store.returnAirport.Code === terms.origin[1] &&
        store.homeAirport.Code === terms.destination[1]
        ))
      ) {
        this.loading = true
        flight.search_id = this.$store.state.flightResults.search_id
        this.$store.dispatch('setFlight', flight)
        this.$store.dispatch('setStayDates', { from: flight.flightSegments[0].leg.arrivalDate, to: flight.flightSegments[1].leg.departureDate })
        const body = {
        //   packageId: this.$store.state.packageDetails.id,
        //   cities: [
        //     {
        //       code: flight.flightSegments[0].leg.arrivalLocationCode === 'MED' ? flight.flightSegments[0].leg.arrivalLocationCode : 'MAC',
        //       date: flight.flightSegments[0].leg.arrivalDate + ' ' + flight.flightSegments[0].leg.arrivalTime
        //     },
        //     {
        //       code: flight.flightSegments[1].leg.departureLocationCode === 'MED' ? flight.flightSegments[1].leg.departureLocationCode : 'MAC',
        //       date: flight.flightSegments[1].leg.departureDate + ' ' + flight.flightSegments[1].leg.departureTime
        //     }
        //   ]
          flight_id: flight.id,
          search_id: flight.search_id
        }
        packages.getHotels(body).then(res => {
          if (res.data.data.max_nights >= 2) {
            this.$store.dispatch('setCities', res.data.data.package.cities)
            const umrahPackage = res.data.data.package
            umrahPackage.dates = res.data.data.dates
            umrahPackage.visaOnly = res.data.data.visaOnly
            umrahPackage.max_nights = res.data.data.max_nights
            umrahPackage.firstNightOptional = res.data.data.firstNightOptional
            umrahPackage.lastNightOptional = res.data.data.lastNightOptional
            this.$store.dispatch('setPackage', umrahPackage)
            this.$router.push({ name: 'packageHotels' })
          } else {
            this.$emit('error', 'Minimum number of Umrah package is 2 nights, please increase the package dates range')
          }
        }).catch(() => {
          this.$emit('error', 'Couldnot retrieve hotels for these dates')
        }).finally(() => {
          this.loading = false
        })
      } else {
        this.$emit('error', 'You have changed the search criteria please click search to continue!')
      }
    }
  },
  created () {
    if (!this.$store.state.packageDetails) this.$router.push({ name: 'Home' })
  }
}
</script>

<style>
    .price {
        border-left: 1px solid #9e9e9e;
    }
    .layover {
        background-color: #fff;
        position: absolute;
        padding: 3px 10px;
        left: 50%;
        transform: translate(-50%, -15px);
    }
    .v-data-table
    /* /deep/ */
    tbody
    /* /deep/ */
    tr:hover:not(.v-data-table__expanded__content) {
        padding-top: 5px;
        background: white !important;
    }
    .airline {
        font-size: 12px;
    }
    .date {
        z-index: 1;
        background-color: white;
        position: relative;
    }
    .segments {
        position: relative;
    }
    h5 {
        margin-top: 10px;
    }
    .select {
        position: relative;
        border-left: 0.1px solid #E0E0E0;
    }
    .actions {
        border-top: 0.1px solid #E0E0E0;
        background-color: #F3F3F3;
    }
    .stops {
        transform: rotate(90deg) translate(-10px, -50%);
    }
    .table {
        width: 100%;
    }
    .list-enter, .list-leave-to {
        opacity: 0;
    }
    .list-enter-active, .list-leave-active {
        transition: opacity 0.5s ease;
    }
</style>
